@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;500;600;700;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


body,p,a,ul,li,button{
padding: 0;
margin: 0;
  text-decoration: none;
}

body{
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  color: #323232;
  background-color: #F0E1DC;
  /* max-width: 800px; */
  width: 100%;
  justify-content: center;
  align-items: center;
}

:root {
  --light-grey: #F6F9FC;
  --dark-terminal-color: #0A2540;
  --accent-color: #635BFF;
  --radius: 3px;
  align-items: center;
  justify-content: center;

}
.card{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  
}
.custom-select{
  max-width: 70%;
}
.title{
 
  font-family: 'Raleway';

  color: var(--brand-dark-grey, #323232);
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-right: auto;
margin-bottom: 5px;


}
.title2{
 
  font-family: 'Raleway';

  color: var(--brand-dark-grey, #323232);
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-right: auto;
margin-bottom: 5px;
margin-top: 20px;


}
.valueText{
  font-family: 'Raleway';
  color: var(--brand-dark-grey, #323232);
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;

}
.cardDiv{
  border-radius: var(--radius);
  border: 0;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width:300px;
  height: 30px;
  color:#AA0028;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;

}
.inputStyle {
  /* Common properties */
  color: #ff0;
  font-weight: 500;

  background-color: #00D924;
  /* font-family: Roboto, Open Sans, Segoe UI, sans-serif; */
  font-size: 16px;
  -webkit-font-smoothing: antialiased;

  /* Webkit Autofill */
  /* Note: CSS property names cannot start with a hyphen, so we use camelCase */
  -webkit-text-fill-color: #fce883;

  /* Placeholder */
  ::placeholder {
    color: #87BBFD;
  }
}
.cardText{
  color: var(--brand-dark-grey, #323232);
font-family: 'Raleway', sans-serif;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.icon{
  max-width: 100px;
}
.main-container{
  margin-left: 15px;
  margin-top: 40px ;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.main-container-success{
  margin-left: 15px;
  margin-top: 40px ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cardElementTitle {
  
  font-size: 12;
  font-family: 'Raleway';
}
.cardElementContainer{
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.success{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 15%;
}

#radioBtn{
  width: 20px;
}
/* body {
  font-family: 'Raleway';
  font-size: 1.2em;
  color: var(--dark-terminal-color);
  background-color: #F0E1DC;
  max-width: 800px;
  width: 100%;
  justify-content: center;
  align-items: center;
} */
input[type=text] {
  border: none;
  background-color: #F0E1DC;
  width: 100%;
  height: 20px;
  font-size: 16px;
  color:#AA0028;
  padding-left: 0px;
}
input:focus{
  outline: none;
}
input::placeholder {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Raleway';
}
.loader {
  display: inline-block;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #AA0028;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  animation: spin 1s linear infinite;
  margin-left: 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.title {
  font-family: 'Raleway';
  color: var(--brand-dark-grey, #323232);
  font-size: 1.5rem; /* Default size for larger screens */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: auto;
  margin-bottom: 5px;
}

/* Smaller screens (e.g., Nexus 5) */

.message{
  color:red;
  font-size: 16px;
  font-family: 'Raleway';
  margin-top: 20px;
}

main {
  width: 480px;
}

form > * {
  margin: 10px 0;
}

h4{
  color:#323232;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Raleway';
}
.organization{
  color:#323232;
  font-size: 16px;
  font-family: 'Raleway';
  margin-left: 15px;
}
.text{
  color:#323232;
  font-size: 15px;
  font-family: 'Raleway';
}
.btnDiv{
  margin-top: 15px;
}

.cardElement{
  background: #FAF2F0;
  border-radius: var(--radius);
  color: black;
  border: 0;
  margin-top: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
  font-size: 0.8em;
  height: 155px;
  width:155px ;
  font-size: 12px;
  border-radius: 16px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 17px;

}
.cardElementSelected{
  background: #FAF2F0;
  border-radius: var(--radius);
  color: black;
  margin-top: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.8em;
  height: 155px;
  width:155px ;
  font-size: 12px;
  border-radius: 16px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  margin-right: 17px;

}
/* .organizationsWrapper{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20;
} */
 .grid-container{
  display: grid;
grid-template-columns: repeat(2, minmax(155px, 1fr));
gap:15px;
justify-content: center;

 }
.amount-box{
  background: #FAF2F0;
  border-radius: var(--radius);
  color: black;
  margin-top: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
  font-size: 0.8em;
  height: 40px;
  width:59px ;
  font-size: 14px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;

}
.amount-box-selected{
  background: #FAF2F0;
  border-radius: var(--radius);
  color: black;
  margin-top: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
  font-size: 0.8em;
  height: 40px;
  width:59px ;
  font-size: 14px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.amountBox{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}
.donate{
  color: var(--brand-light-beige, #F0E1DC);
text-align: center;
font-family: 'Raleway', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.donate1{
  color: var(--brand-light-beige, #F0E1DC);
text-align: center;
font-family: 'Raleway', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 15px;
}



button {
  background: #AA0028;
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 8px 10px;
  margin-top: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
  font-size: 0.8em;
  width:300px ;
  border-radius: 100px;
  align-self: center;
font-family: 'Raleway', sans-serif;
height: 40px;

}
.editBtn{
  background: #E8D5CF;
  border-radius: var(--radius);
  color: black;
  border: 0;
  padding: 8px 10px;
font-family: 'Raleway', sans-serif;

  margin-top: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
  font-size: 0.8em;
  width:300px ;
  border-radius: 100px;
  align-self: center;
  height: 40px;
}
button:hover {
  filter: contrast(115%);
}
button:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
button:disabled {
  opacity: 0.5;
  cursor: none;
}

input, select {
  display: block;
  font-size: 1.1em;
  margin-bottom: 10px;
}

label {
  display: block;
  font-size: 0.8em;
  font-weight: 600;
  margin-bottom: 5px;
  text-transform: capitalize;
  letter-spacing: 0.05em;
  color: #000000;

}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
  color:#323232;
  margin-top: 8px;
  background-color: #FAF2F0;
  border-radius: 8px ;

}
.container2{
  display: flex;
  justify-content: space-between;
  align-items: center;
padding-left: 16px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
  color:#323232;
  margin-top: 8px;
  width: 327px;
  background-color: transparent;
  border-radius: 8px ;
  margin-top: 24px;
}
.delete{
  background: #E8D5CF;
  border-radius: var(--radius);
  color: black;
  border: 0;
  padding: 8px 10px;
font-family: 'Raleway', sans-serif;

  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
  font-size: 0.8em;
  border-radius: 100px;
  align-self: center;
  width: 104px;
  height: 40px;
  margin-top: 0px;
  color: #AA0028;
text-align: center;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.xtext{
  color:  gray;
  font-family: 'Raleway', sans-serif;

font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.addNew{
  color: #000;
text-align: center;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
  font-family: 'Raleway', sans-serif;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: #AA0028;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
 	top: 8.5px;
	left: 8.5px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}

a {
  color: var(--accent-color);
  font-weight: 900;
}

small {
  font-size: .6em;
}

fieldset, input, select {
  border: 1px solid #efefef;
}

#messages {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
  display: none; /* hide initially, then show once the first message arrives */
  background-color: #0A253C;
  color: #00D924;
  padding: 20px;
  margin: 20px 0;
  border-radius: var(--radius);
  font-size:0.7em;
}
.add-new-card-button{
  width: auto;
}
.success-text{
    font-size: 16px;
    font-weight: 500;
    color: #333333;
 margin-top: 15px;
    text-align: center;
}
.success-icon{
  max-width: 120px;
}
.success-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 90%;
  margin-top: 15%;
}



.checkbox {

  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
}

.checkbox.checked {
  background-color: #AA0028;


}
.checkboxWrapper{
  position: relative;
  margin-left: auto;
  top: 0px;
  left: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #AA0028;
  display: flex ;
  justify-content: center;
  align-items: center;

}

.read-more {
  color: #0E6A8B;
  margin-top: 10px;
}
/* styles.css or App.css */

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.text-organ{
  width: 300px;
  font-size: 18px;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  margin-right: 12px;
  margin-left: 12px;
}

.popup-content {
  width: 327px;
  max-width: 500px;
  height: 80%;
  flex-wrap: wrap;
  background-color: rgba(246, 240, 238, 1);
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  overflow-y: auto;   
  scroll-behavior: smooth;
  border-radius: 20px;
  
  
}
.close-icon{
  position: relative;
  margin-left: auto;
  cursor: pointer;
  
}
.title-x{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  margin-top: 30px;
  margin-right: 21px;
  margin-left: 21px;

}
.titleX{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
}
.title-popup{
  font-size: 19px;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  color: #323232;

}
.logos{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 30px;
}
/* Container for the checkbox and terms */
.terms-checkbox {
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
}

/* Styling the checkbox */
.terms-checkbox input {
  margin-right: 10px;
  width: 30px;
  height: 16px;
  cursor: pointer;
}

/* Styling the label text for the checkbox */
.terms-checkbox label {
  color: #000000;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 15px;

}

/* Styling the clickable "Terms and Conditions" text */
.terms-checkbox span {
  color: rgba(170, 0, 40, 1); /* Red shade */
  text-decoration: underline;
  cursor: pointer;

}
/* Hide the default checkbox appearance */
.terms-checkbox input {
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid rgba(170, 0, 40, 1); /* Border color */
  border-radius: 3px; /* Optional, to make it square */
  cursor: pointer;
  position: relative;
}

/* Checked state background color */
.terms-checkbox input:checked {
  background-color: rgba(170, 0, 40, 1);
  border: 1px solid rgba(170, 0, 40, 1); /* Keep border same as background */
}

/* Custom checkmark */
.terms-checkbox input:checked::after {
  content: '✔';
  color: white;
  font-size: 12px;
  position: absolute;
  top: -1px;
  left: 3px;
}
.icon{
  margin-right: 8px;
  height: 50px;
  margin-bottom: 8px;
}



@media (max-width: 360px) {
  .title {
    font-size: 1.2rem; /* Smaller font for smaller devices */
  }
  .title2{
    font-size: 1.2rem; /* Smaller font for smaller devices */
  }
  .amount-box{
    width: 50px;
    height: 30px;
    font-size: 10px;
  }
  .terms-checkbox  {
    font-size: 12px;
  }
  .cardElement {
    width: 120px;
    height: 120px;
    font-size: 10px;
  }
  .cardElementSelected {
    width: 120px;
    height: 120px;
    font-size: 10px;
  }
  .container2{
    width: 300px;
  }
.cardElementTitle{
  font-size: 10;
}
.checkboxWrapper {
  width: 15px;
  height: 15px;
  top: 10px;
  left: 0px;
}

  .checkbox {
    width: 15px;
    height: 15px;
    top: 10px;
  }
  .checkbox.checked {
    width: 15px;
    height: 15px;
    top: 10px;
    left: 10px;
  }
  .icon{
    height: 50px;
    width: 50px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .grid-container{
    display: grid;
    grid-template-columns: repeat(2, minmax(155px, 1fr));
    gap:0px;
    justify-content: center;
  }

 .terms-checkbox label {
  font-size: 12px;
 }

.donate1{
  color: var(--brand-light-beige, #F0E1DC);
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 15px;

}
.btnDiv{
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}
#donate1{
  width: 300px;
  align-self: center;
  margin-top: 5px;
}
}

/* Larger screens (e.g., iPhone 14 Pro Max) */
@media (min-width: 428px) {
  .title {
    font-size: 1.8rem; /* Larger font for larger devices */
  }
  .title2{
    font-size: 1.8rem; /* Larger font for larger devices */
  }
  .amount-box{
    width: 59px;
    height: 40px;
    font-size: 14px;
  }
  .amount-box-selected{
    width: 59px;
    height: 40px;
    font-size: 14px;
  }
  .terms-checkbox label {
    font-size: 14px;
  }
  .cardElement {
    width: 155px;
    height: 155px;
    font-size: 12px;
  }
  .cardElementSelected {
    width: 155px;
    height: 155px;
    font-size: 12px;
  }
  .container2{
    width: 327px;
  }
  .checkboxWrapper {
    width: 20px;
    height: 20px;
    top: 10px;
    left: 0px;
  }
  .checkbox {
    width: 20px;
    height: 20px;
    top: 10px;
  }
  .checkbox.checked {
    width: 20px;
    height: 20px;
    top: 10px;
    left: 10px;
  }
  .icon{
    height: 50px;
    width: 50px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .grid-container{
    display: grid;
    grid-template-columns: repeat(2, minmax(155px, 1fr));
    gap:15px;
    justify-content: center;
  }

  .terms-checkbox label {
    font-size: 14px;
  } 
  .donate1{
    color: var(--brand-light-beige, #F0E1DC);
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 15px;
  }
  .btnDiv{
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
  }
  #donate1{
    width: 300px;
    align-self: center;
    margin-top: 5px;
  }

}

/* Optional: Handle mid-sized devices if needed */
@media (min-width: 361px) and (max-width: 427px) {
  .title {
    font-size: 1.5rem; /* Medium font size for mid-sized devices */
  }
  .title2 {
    font-size: 1.5rem; /* Medium font size for mid-sized devices */
  }
  .amount-box{
    width: 60px;
    height: 40px;
    font-size: 10px;
  }
  .amount-box-selected{
    width: 60px;
    height: 40px;
    font-size: 12px;
  }
  .terms-checkbox label {
    font-size: 12px;
  }
  .cardElement {
    width: 140px;
    height: 130px;
    font-size: 12px;
  }
  .cardElementSelected {
    width: 140px;
    height: 130px;
    font-size: 12px;
  }
  .container2{
    width: 300px;
  }
  .checkboxWrapper {
    width: 15px;
    height: 15px;
    top: 10px;
    left: 0px;
  }
  .checkbox {
    width: 15px;
    height: 15px;
    top: 10px;
  }
  .checkbox.checked {
    width: 15px;
    height: 15px;
    top: 10px;
    left: 10px;
  }
  .icon{
    height: 50px;
    width: 50px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .grid-container{
    display: grid;
    grid-template-columns: repeat(2, minmax(155px, 1fr));
    gap:0px;
    justify-content: center;
  }
 
  .terms-checkbox label {
    font-size: 12px;
  }
  .donate1{
    color: var(--brand-light-beige, #F0E1DC);
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 15px;
  }
  .btnDiv{
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
  }
  #donate1{
    width: 300px;
    align-self: center;
    margin-top: 5px;
  }
}

